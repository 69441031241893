import React, {useState} from "react";
import resumeFile from "../documents/CV.pdf";
import ResumeBlock from "./Resume/ResumeBlock";
import {useTranslation} from "react-i18next";

const Resume = ({classicHeader, darkTheme}) => {
    const [showMore, setShowMore] = useState(false);
    const { t } = useTranslation('resume');

    const educationDetails = [
        {
            yearRange: t("education.study.yearRange"),
            title: t("education.study.title"),
            place: t("education.study.place"),
            desc: t("education.study.desc")
        },
        {
            yearRange: t("education.apprenticeship.yearRange"),
            title: t("education.apprenticeship.title"),
            place: t("education.apprenticeship.place"),
            desc: t("education.apprenticeship.desc")
        },
        {
            yearRange: t("education.highSchool.yearRange"),
            title: t("education.highSchool.title"),
            place: t("education.highSchool.place"),
            desc: t("education.highSchool.desc")
        }
    ].filter(
        (value,index) => {
            return showMore || value.highlight
        }
    );

    const experienceDetails = [
        {
            yearRange: t("experience.nfon.yearRange"),
            title: t("experience.nfon.title"),
            place: t("experience.nfon.place"),
            desc: t("experience.nfon.desc"),
            highlight: true
        },
        {
            yearRange: t("experience.check24.yearRange"),
            title: t("experience.check24.title"),
            place: t("experience.check24.place"),
            desc: t("experience.check24.desc"),
            highlight: true
        },
        {
            yearRange: t("experience.wildcat.yearRange"),
            title: t("experience.wildcat.title"),
            place: t("experience.wildcat.place"),
            desc: t("experience.wildcat.desc"),
            highlight: true
        },
        {
            yearRange: t("experience.wikom.yearRange"),
            title: t("experience.wikom.title"),
            place: t("experience.wikom.place"),
            desc: t("experience.wikom.desc")
        },
        {
            yearRange: t("experience.bestit.yearRange"),
            title: t("experience.bestit.title"),
            place: t("experience.bestit.place"),
            desc: t("experience.bestit.desc")
        },
        {
            yearRange: t("experience.gruenspar.yearRange"),
            title: t("experience.gruenspar.title"),
            place: t("experience.gruenspar.place"),
            desc: t("experience.gruenspar.desc")
        },
        {
            yearRange: t("experience.shopwareag.yearRange"),
            title: t("experience.shopwareag.title"),
            place: t("experience.shopwareag.place"),
            desc: t("experience.shopwareag.desc")
        },
        {
            yearRange: t("experience.backzoom.yearRange"),
            title: t("experience.backzoom.title"),
            place: t("experience.backzoom.place"),
            desc: t("experience.backzoom.desc")
        },
        {
            yearRange: t("experience.mittelstandsbuero.yearRange"),
            title: t("experience.mittelstandsbuero.title"),
            place: t("experience.mittelstandsbuero.place"),
            desc: t("experience.mittelstandsbuero.desc")
        },
        {
            yearRange: t("experience.familienbetrieb.yearRange"),
            title: t("experience.familienbetrieb.title"),
            place: t("experience.familienbetrieb.place"),
            desc: t("experience.familienbetrieb.desc")
        }
    ].filter(
        (value,index) => {
            return showMore || value.highlight
        }
    );

    let webDevelopmentSkills = t('webDevelopmentSkills')
    let devOpsAndTesting = t('devOpsAndTesting')
    let ciCdPipelineImplementation = t('ciCdPipelineImplementation');
    let softwareEngineeringPrinciples = t('softwareEngineeringPrinciples');
    let aiMl = t('aiMl');
    let design3D = t('design3D');

    const skills = [
        {
            "name": "PHP Development",
            "percent": 90,
            "category": webDevelopmentSkills
        },
        {
            "name": "JavaScript",
            "percent": 85,
            "category": webDevelopmentSkills
        },
        {
            "name": "Golang",
            "percent": 70,
            "category": webDevelopmentSkills
        },
        {
            "name": "Bash",
            "percent": 70,
            "category": webDevelopmentSkills
        },
        {
            "name": "SQL",
            "percent": 85,
            "category": webDevelopmentSkills
        },
        {
            "name": "API Development",
            "percent": 85,
            "category": webDevelopmentSkills
        },
        {
            "name": "Shopware Development",
            "percent": 75,
            "category": webDevelopmentSkills
        },
        {
            "name": "Magento Development",
            "percent": 70,
            "category": webDevelopmentSkills
        },
        {
            "name": "Full Stack Development",
            "percent": 80,
            "category": webDevelopmentSkills
        },
        {
            "name": "React / ExtJs / HTML / CSS",
            "percent": 75,
            "category": webDevelopmentSkills
        },
        {
            "name": "Docker",
            "percent": 90,
            "category": devOpsAndTesting
        },
        {
            "name": ciCdPipelineImplementation,
            "percent": 90,
            "category": devOpsAndTesting
        },
        {
            "name": "Unit and Functional Testing",
            "percent": 90,
            "category": devOpsAndTesting
        },
        {
            "name": "Problem Solving",
            "percent": 90,
            "category": softwareEngineeringPrinciples
        },
        {
            "name": "Refactoring",
            "percent": 85,
            "category": softwareEngineeringPrinciples
        },
        {
            "name": "Clean Code",
            "percent": 85,
            "category": softwareEngineeringPrinciples
        },
        {
            "name": "Software Pattern",
            "percent": 80,
            "category": softwareEngineeringPrinciples
        },
        {
            "name": "Software Architecture",
            "percent": 85,
            "category": softwareEngineeringPrinciples
        },
        {
            "name": "Prompt Design",
            "percent": 60,
            "category": aiMl
        },
        {
            "name": "Python",
            "percent": 60,
            "category": aiMl
        },
        {
            "name": "Sympy / Numpy",
            "percent": 65,
            "category": aiMl
        },
        {
            "name": "Tensorflow / Neural Networks",
            "percent": 40,
            "category": aiMl
        },
        {
            "name": "Blender",
            "percent": 60,
            "category": design3D
        },
        {
            "name": "Photoshop",
            "percent": 10,
            "category": design3D
        }
    ];


    let header =
        <div className="position-relative d-flex text-center mb-5">
            <h2
                className={
                    "text-24  text-uppercase fw-600 w-100 mb-0 " +
                    (darkTheme ? "text-light opacity-3" : "text-light opacity-4")
                }
            >
                {t('summary')}
            </h2>
            <p
                className={
                    "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                    (darkTheme ? "text-white" : "text-dark")
                }
            >
                {t('resume')}
                <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"/>
            </p>
        </div>;

    let experience = <div className="col-md-12">
        {/* My Experience */}
        <h2
            className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
            }
        >
            {t('myExperience')}
        </h2>
        {
            experienceDetails.length > 0
            && experienceDetails

                .map(
                    (value, index) => (
                        <ResumeBlock darkTheme={darkTheme} value={value} key={index}></ResumeBlock>
                    )
                )
        }
    </div>;


    let education = <div className="row gx-5" style={{display: educationDetails.length === 0 ? 'none' : 'block'}}>
        <div className="col-md-12">
            <h2
                className={
                    "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
                }
            >
                {t('myEducation')}
            </h2>
            {
                educationDetails.length > 0
                && educationDetails
                    .filter(
                        (value,index) => {
                            return showMore || value.highlight
                        }
                    )
                    .map(
                        (value, index) => (
                            <ResumeBlock darkTheme={darkTheme} value={value} key={value} ></ResumeBlock>
                        )
                    )
            }
        </div>
    </div>;

    let myskills = (
        <>
            <h2 className={"text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")}>
                {t('mySkills')}
            </h2>

            {skills
                .reduce(
                    (uniqueCategories, skill) =>
                        uniqueCategories.includes(skill.category) ? uniqueCategories : [...uniqueCategories, skill.category],
                    []
                ).map(category => (
                <div key={category} className={"mb-5"}>
                    <h3 className={"fw-600 " + (darkTheme ? "text-white" : "")}>{category}</h3>
                    <div className="row gx-5">
                        {skills.filter(skill => skill.category === category).map((skill, index) => (
                            <div key={category + '_' + index} className="col-md-6">
                                <p
                                    className={
                                        " fw-500 text-start mb-2 " +
                                        (darkTheme ? "text-light" : "text-dark")
                                    }
                                >
                                    {skill.name}{" "}
                                    <span className="float-end">{skill.percent}%</span>
                                </p>
                                <div
                                    className={
                                        "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                                    }
                                >
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{width: skill.percent + "%"}}
                                        aria-valuenow={skill.percent}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );

    let shoMoreButton = <div className="text-center mt-5">
        <button
            className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
            onClick={() => {
                setShowMore(!showMore);
            }}
        >
            {!showMore ? t("showMore") : t("showLess")}
        </button>
    </div>;

    let downloadCV = <div className="text-center mt-5">
        <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
        >
            Download CV
            <span className="ms-1">
          <i className="fas fa-download"/>
        </span>
        </a>
    </div>;

    return (
        <section id="resume" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
            <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                {header}
                {experience}
                {education}
                {shoMoreButton}
                {myskills}
                {downloadCV}
            </div>
        </section>
    );
};

export default Resume;
