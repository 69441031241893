import React from "react";

const Impressum = ({ darkTheme }) => {
  return (
    <div
      id="Impressum"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Ren&eacute; Gerritsen<br/>
              Musterladen<br/>
              Banhofstra&szlig;e 3<br/>
              48619 Heek</p>

            <h2>Kontakt</h2>
            <p>Telefon: 015123581441<br/>
              E-Mail: rene.gerritsen@me.com</p>

            <h2>Redaktionell verantwortlich</h2>
            <p>Ren&eacute; Gerritsen<br/>
              Bahnhofstra&szlig;e 3<br/>
              48619 Heek</p>

            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
              Verbraucherschlichtungsstelle teilzunehmen.</p>

            <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
