import React from "react";

import posts from "./posts.json"
import {Link, useParams} from "react-router-dom";
import Heading from "./Heading";
import Markdown from 'react-markdown'
import rehypeClassNames from 'rehype-class-names'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {nord} from 'react-syntax-highlighter/dist/esm/styles/prism'
const moment = require('moment');

const Entry = ({darkTheme, classicHeader}) => {
    if (!Object.hasOwn) {
        Object.hasOwn = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
    }

    const { slug } = useParams();

    const classMapping = {
        h1: "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "text-black"),
        h2: "text-5 fw-600 mb-3 " + (darkTheme ? "text-white" : "text-black"),
        h3: "text-4 fw-600 mb-3 " + (darkTheme ? "text-white" : "text-black"),
        h4: "text-3 fw-600 mb-3 " + (darkTheme ? "text-white" : "text-black"),
        h5: "text-2 fw-600 mb-3 " + (darkTheme ? "text-white" : "text-black"),
        p: "" + (darkTheme ? "text-white" : "text-black"),
        li: "" + (darkTheme ? "text-white" : "text-black"),
        strong: 'mein-fett-text-klasse',
        em: 'mein-kursiv-text-klasse'
    }

    return (
        <>
        <section id="entry" className={"section " + (darkTheme ? "bg-dark-2" : "")}>
            <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                <Heading darkTheme={darkTheme}/>
                <div className="my-4 row">
                    {posts.filter(post => post.slug === slug).map((post) => (
                        <div key={post.id} className="col-md-12">
                            <div
                                className="row g-0 flex-md-row mb-4 position-relative">
                                <div className="col p-4 d-flex flex-column position-static">
                                    <strong className="text-primary">{post.category}</strong>
                                    <h2 className={"text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")}>{post.title}</h2>
                                    <strong className="text-secondary">{moment(post.date).format("DD.MM.YY")}</strong>
                                    <Markdown
                                        className={"card-text mb-auto " + darkTheme ? "text-white-50" : ""}
                                        rehypePlugins={[[rehypeClassNames, classMapping]]}
                                        components={{
                                            code(props) {
                                                const {children, className, node, ...rest} = props
                                                const match = /language-(\w+)/.exec(className || '')
                                                return match ? (
                                                    <SyntaxHighlighter
                                                        {...rest}
                                                        PreTag="div"
                                                        children={String(children).replace(/\n$/, '')}
                                                        language={match[1]}
                                                        style={nord}
                                                    />
                                                ) : (
                                                    <code {...rest} className={className}>
                                                        {children}
                                                    </code>
                                                )
                                            }
                                        }}

                                    >
                                        {post.content}
                                    </Markdown>
                                </div>
                            </div>
                        </div>
                    ))}
                    <Link to={`/blog`}>Back</Link>
                </div>
            </div>
        </section>
        </>
    );
}

export default Entry;
