import React from "react";
import {useTranslation} from "react-i18next";

const ProjectDetailsModal = ({ darkTheme, projectDetails }) => {
  const { t } = useTranslation('my-work');
  return (
    <>
      <div
        className="modal fade bg-dark-1"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div
            className={
              "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
            }
          >
            <div className="modal-body">
              <button
                type="button"
                className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div
                className={
                  "container ajax-container " +
                  (darkTheme ? "bg-dark-2 text-light" : "")
                }
              >
                <h2
                  className={
                    "text-6 font-weight-600 text-center mb-4" +
                    (darkTheme ? " text-white" : "")
                  }
                >
                  {projectDetails?.title}
                </h2>
                <div className="row g-4">
                  <div className="col-md-7">
                      <div className="item">
                        <img
                          className="img-fluid"
                          alt=""
                          src={projectDetails?.thumbImage}
                        />
                      </div>
                  </div>
                  <div className="col-md-5">
                    <h4
                        className={
                            "text-4 font-weight-600" +
                            (darkTheme ? " text-white" : "")
                        }
                    >
                      {t('project')}:
                    </h4>
                    <p>{projectDetails?.project}</p>
                    <ul
                        className={
                            "list-style-2 " + (darkTheme ? "list-style-light" : "")
                        }
                    >
                      <li>
                        <span
                            className={
                                "text-dark font-weight-600 me-2" +
                                (darkTheme ? " text-white" : "")
                            }
                        >
                          Client:
                        </span>
                        {projectDetails?.client}
                      </li>
                      <li>
                        <span
                            className={
                                "text-dark font-weight-600 me-2" +
                                (darkTheme ? " text-white" : "")
                            }
                        >
                          {t('technologies')}:
                        </span>
                        {projectDetails?.technologies}
                      </li>
                      <li>
                        <span
                            className={
                                "text-dark font-weight-600 me-2" +
                                (darkTheme ? " text-white" : "")
                            }
                        >
                          Date:
                        </span>
                        {projectDetails?.date}
                      </li>
                      <li>
                        <span
                            className={
                                "text-dark font-weight-600 me-2" +
                                (darkTheme ? " text-white" : "")
                            }
                        >
                          URL:
                        </span>
                        <a
                            href={projectDetails?.url?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          {projectDetails?.url?.name}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetailsModal;
