import React from "react";

const ResumeBlock = ({value, darkTheme}) => {
    return <div
        className={
            "bg-white  rounded p-4 mb-4 " +
            (darkTheme ? "bg-dark" : "bg-white border")
        }
    >
        <p className="badge bg-primary text-2 fw-400">
            {value.yearRange}
        </p>
        <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
            {value.title}
        </h3>
        <p className={darkTheme ? "text-primary" : "text-danger"}>
            {value.place}
        </p>
        <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
            {value.desc}
        </p>
    </div>;
};

export default ResumeBlock;

