import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import de from "./locales/de/translation.json"
import en from "./locales/en/translation.json"

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {escapeValue: false},
        resources: {en: en,de: de}
    })

export default i18n
