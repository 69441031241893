import React from "react";
import Home from "./Home";
import Blog from "./Blog";
import AboutMe from "./About";
import Services from "./Services";
import Resume from "./Resume";
import MyWork from "./MyWork";
import Testimonials from "./Testimonials";
import Contact from "./Contact";

const Profile = ({ darkTheme , classicHeader, handleNavClick}) => {
  return (
      <div id="content" role="main">
        <Home
            classicHeader={classicHeader}
            darkTheme={darkTheme}
            handleNavClick={handleNavClick}
        ></Home>
        <Blog
            classicHeader={classicHeader}
            darkTheme={darkTheme}
        ></Blog>
        <AboutMe
            classicHeader={classicHeader}
            darkTheme={darkTheme}
        ></AboutMe>
        <Services
            classicHeader={classicHeader}
            darkTheme={darkTheme}
        ></Services>
        <Resume
            classicHeader={classicHeader}
            darkTheme={darkTheme}
        ></Resume>
        <MyWork
            classicHeader={classicHeader}
            darkTheme={darkTheme}
        ></MyWork>
        <Testimonials
            classicHeader={classicHeader}
            darkTheme={darkTheme}
        ></Testimonials>
      </div>
  );
};

export default Profile;

/**
<Contact
            classicHeader={classicHeader}
            darkTheme={darkTheme}
        ></Contact>
 */
