import React from "react";

import posts from "./posts.json"
import {Link} from "react-router-dom";
import Heading from "./Heading";
const moment = require('moment');

const List = ({darkTheme, classicHeader}) => {
    return (
        <>
        <section id="blog" className={"section " + (darkTheme ? "bg-dark-2" : "")}>
            <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                <Heading darkTheme={darkTheme}/>
                <div className="my-4 row">
                    {posts.map((post) => (
                        <Link key={post.id} to={`/blog/` + post.slug}>
                        <div key={post.id} className="col-md-12">
                            <div
                                className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm position-relative">
                                <div className="col p-4 d-flex flex-column position-static">
                                    <strong className="text-primary">{post.category}</strong>
                                    <h2 className={"text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")}>{post.title}</h2>
                                    <strong className="text-secondary">{moment(post.date).format("DD.MM.YY")}</strong>
                                    <p className={"card-text mb-auto " + darkTheme ? "text-white-50" : ""}>{post.summary}</p>
                                </div>
                                <div className="col-auto d-none d-lg-block">
                                    <img
                                        alt={(post.summary.length > 20)?post.summary.substring(0,20) + '...' : post.summary}
                                        style={{width: '200px', height: '100%', objectFit: 'cover'}}
                                        src={"/images/blog/" + post.id + ".webp"}
                                    />
                                </div>
                            </div>
                        </div>
                        </Link>
                    ))}
                </div>
            </div>
        </section>
        </>
    );
}

export default List;
