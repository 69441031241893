import React from "react";
import resumeFile from "../documents/CV.pdf";
import {useTranslation} from "react-i18next";
const AboutMe = ({ classicHeader, darkTheme }) => {
  const { t } = useTranslation('about-me');

  const aboutMeTexts = [t('p1'), t('p2'), t('p3'), t('p4')];

  return (
      <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
                className={
                    "text-24  text-uppercase fw-600 w-100 mb-0 " +
                    (darkTheme ? "text-light opacity-1" : "text-light opacity-4")
                }
            >
              {t('title')}
            </h2>
            <p
                className={
                    "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                    (darkTheme ? "text-white" : "text-dark")
                }
            >
              {t('subtitle')}
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"/>
            </p>
          </div>
          {/* Heading end*/}
          <div className="row gy-5">
            {/* About me content start */}
            <div className="col-lg-7 col-xl-8 text-center text-lg-start">
              <h2
                  className={
                      "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
                  }
              >
                {t('iam')}<span className="text-primary">{t('name')}</span>{t('a-developer')}
              </h2>
              {aboutMeTexts.map((text, index) => (
                  <p key={index} className={darkTheme ? "text-white-50" : ""}>
                    {text}
                  </p>
              ))}
            </div>
            {/* About me content end */}
            {/* about me personal detials start */}
            <div className="col-lg-5 col-xl-4">
              <div className="ps-lg-4">
                <ul
                    className={
                        "list-style-2 " +
                        (darkTheme ? "list-style-light text-light" : "")
                    }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>René Gerritsen
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:chat@simone.com">rene.gerritsen@me.com</a>
                </li>
                <li>
                  <span className="fw-600 me-2">{t('age')}:</span>42
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">{t('from')}:</span>Heek,
                  Germany
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4 className={"text-12  mb-0 " + (darkTheme ? "text-white-50" : "text-muted")}>
                  <span>20 {t('years')}</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {t('experience')}
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4 className={"text-12  mb-0 " + (darkTheme ? "text-white-50" : "text-muted")}>
                  <span>7+</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {t('languages')}
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4 className={"text-12  mb-0 " + (darkTheme ? "text-white-50" : "text-muted")}>
                  <span>100%</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {t('dockerCloud')}
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4 className={"text-12  mb-0 " + (darkTheme ? "text-white-50" : "text-muted")}>
                  <span>&infin;</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {t('technology')}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutMe;
