import "./App.scss";
import List from "./components/Blog/List";
import Entry from "./components/Blog/Entry";
import Footer from "./components/Footer";
import Profile from "./components/Profile";
import React, {useEffect, useState} from "react";
import {commonConfig} from "./config/commonConfig";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";
import PreLoader from "./components/Preloader";
import {Tooltip} from "./components/Tooltip";
import {createBrowserRouter, RouterProvider, ScrollRestoration} from "react-router-dom";
import ClassicHeader from "./components/ClassicHeader";
import Header from "./components/Header";
import {useTranslation} from "react-i18next";
import "./i18n";


function App() {
    const { t} = useTranslation('app');

    /** @type boolean */
    const classicHeader = commonConfig.classicHeader;
    const [darkTheme, setDarkMode] = useState(true);

    const handleSetDarkMode = () => {
       setDarkMode(!darkTheme);
    }

    const handleNavClick = (section) => {
        document.getElementById(section).scrollIntoView({behavior: "smooth"});
    };

    const [isLoading, setisLoading] = useState(false);
    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            setisLoading(false);
        }, 1000);
        return () => {
            clearTimeout(loadingTimeout);
        };
    }, []);

    /**
     * @type {[{name: string, to: string},{name: string, to: string}]}
     */
    let BlogNavItems = [
        {"name": t("BackToProfile"), "to": "/#about", "type": "route"},
        {"name": t("ArticleList"), "to": "/blog", "type": "route"},
    ];

    /**
     * @type {[{name: string, to: string, type: string}]}
     */
    let navItems = [
        {"name": t("Home"), "to": "home", "type": "link"},
        {"name": t("Blog"), "to": "blog", "type": "link"},
        {"name": t("About Me"), "to": "about", "type": "link"},
        {"name": t("What I Do"), "to": "services", "type": "link"},
        {"name": t("Resume"), "to": "resume", "type": "link"},
        {"name": t("My Work"), "to": "portfolio", "type": "link"},
        {"name": t("Testimonial"), "to": "testimonial", "type": "link"},
        //{"name": t("Contact"), "to": "contact", "type": "link"}
    ]

    const router = createBrowserRouter([
        {
            path: "/",
            element: <div>
                <ScrollRestoration/>
                {classicHeader ? (
                    <ClassicHeader handleNavClick={handleNavClick}></ClassicHeader>
                ) : (
                    <Header
                        handleNavClick={handleNavClick}
                        darkTheme={darkTheme}
                        setDarkMode={handleSetDarkMode}
                        navItems={navItems}
                    ></Header>
                )}
                <Profile darkTheme={darkTheme} classicHeader={classicHeader} handleNavClick={handleNavClick}/>
            </div>,
        },
        {
            path: "/blog",
            element: <div>
                <ScrollRestoration/>
                {classicHeader ? (
                    <ClassicHeader handleNavClick={handleNavClick}></ClassicHeader>
                ) : (
                    <Header
                        handleNavClick={handleNavClick}
                        darkTheme={darkTheme}
                        setDarkMode={handleSetDarkMode}
                        navItems={BlogNavItems}
                    ></Header>
                )}
                <List darkTheme={darkTheme} classicHeader={classicHeader} handleNavClick={handleNavClick}/>
            </div>,
        },
        {
            path: "/blog/:slug",
            element: <div>
                <ScrollRestoration/>
                {classicHeader ? (
                    <ClassicHeader handleNavClick={handleNavClick}></ClassicHeader>
                ) : (
                    <Header
                        handleNavClick={handleNavClick}
                        darkTheme={darkTheme}
                        setDarkMode={handleSetDarkMode}
                        navItems={BlogNavItems}
                    ></Header>
                )}
                <Entry darkTheme={darkTheme} classicHeader={classicHeader} handleNavClick={handleNavClick}/>
            </div>,
        },
    ]);

    return (
            <div
                style={{position: "relative"}}
                className={classicHeader ? "" : "side-header"}
            >
                {isLoading && <PreLoader></PreLoader>}
                <div id="main-wrapper">
                    <RouterProvider router={router}/>
                    <Footer
                        classicHeader={classicHeader}
                        darkTheme={darkTheme}
                        handleNavClick={handleNavClick}
                    ></Footer>
                    {/* back to top */}
                    <Tooltip text={t("back_to_top", 'app')} placement="left">
                          <span
                              id="back-to-top"
                              className="rounded-circle"
                              style={{display:  "inline"}}
                              onClick={() => {
                                  window.scrollTo({top: 0, behavior: "smooth"});
                              }}
                          >
                            <i className="fa fa-chevron-up"></i>
                          </span>
                    </Tooltip>
                    <Impressum darkTheme={darkTheme}></Impressum>
                    <Datenschutz darkTheme={darkTheme}></Datenschutz>
                </div>
            </div>
    );
}

export default App;
