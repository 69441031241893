import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
import {useTranslation} from "react-i18next";
const MyWork = ({ classicHeader, darkTheme }) => {
  const isotope = useRef();
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const { t } = useTranslation('my-work');

  const filters = {
    Oss: t("oss"),
    Talks: t("talks"),
    Videos: t("Videos")
  };

  const projectsData = [
    {
      title: t("projects.unitTestingCiWithPhp.title"),
      project: t("projects.unitTestingCiWithPhp.project"),
      client: "Check24",
      technologies: "PHPUnit, Zend/Laminas, Docker, PHP, Gitlab",
      date: "2017",
      url: {
        name: t("projects.unitTestingCiWithPhp.url.name"),
        link: "https://re2bit.github.io/slides_phpunit_talk/assets/player/KeynoteDHTMLPlayer.html#0",
      },
      thumbImage: "https://github.com/re2bit/slides_phpunit_talk/blob/main/assets/183C7288-86D8-455D-81F4-68E9CF061BA5/thumbnail.jpeg?raw=true",
      categories: [filters.Talks],
    },
    {
      title: t("projects.doctrine2.title"),
      project: t("projects.doctrine2.project"),
      client: "Check24",
      technologies: "Doctrine2, SQL, DQL, PHP, Pattern, Antipattern",
      date: "2018",
      url: {
        name: t("projects.doctrine2.url.name"),
        link: "https://re2bit.github.io/slides_doctrine2/assets/player/KeynoteDHTMLPlayer.html#0",
      },
      thumbImage: "https://github.com/re2bit/slides_doctrine2/blob/main/assets/7CE9B43C-AC99-40DF-B909-20D55B80612E/thumbnail.jpeg?raw=true",
      categories: [filters.Talks],
    },
    {
      title: t("projects.jmsSerializer.title"),
      project: t("projects.jmsSerializer.project"),
      client: "schmittjo/serializer",
      technologies: "PHP",
      date: "2018",
      url: {
        name: t("projects.jmsSerializer.url.name"),
        link: "https://github.com/schmittjoh/serializer/pulls?q=is%3Apr+author%3Are2bit+is%3Aclosed",
      },
      thumbImage: "https://github.com/re2bit/serializer/raw/master/doc/logo-small.png",
      categories: [filters.Oss],
    },
    {
      title: t("projects.intalis.title"),
      project: t("projects.intalis.project"),
      client: "Wildcat",
      technologies: "PHP / Javascript",
      date: "2009",
      url: {
        name: t("projects.intalis.url.name"),
        link: "https://www.youtube.com/watch?v=Lm3Idj3KSMU",
      },
      thumbImage: "images/intalis.jpg",
      categories: [filters.Videos],
    },
    {
      title: t("projects.greenscreen.title"),
      project: t("projects.greenscreen.project"),
      client: "-",
      technologies: "Blender",
      date: "2009",
      url: {
        name: t("projects.greenscreen.url.name"),
        link: "https://www.youtube.com/watch?v=4ExQmCQHnbA ",
      },
      thumbImage: "images/blender.png",
      categories: [filters.Videos],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="my_work"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-light opacity-3" : "text-light opacity-4")
              }
            >
              {t('my-work')}
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {t('links')}
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <button
                            className="popup-ajax stretched-link"
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">{project.title}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default MyWork;
