import React, {useState} from "react";
import {Tooltip} from "./Tooltip";
import {Link} from "react-scroll";
import Toggle from 'react-toggle'
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";

/**
 *
 * @param bool classicHeader
 * @param bool darkTheme
 * @param homeRef
 * @param {function} handleNavClick
 * @param {function} setDarkMode
 * @param {[{name: string, to: string, type: string}]} navItems
 * @returns {Element}
 * @constructor
 */
const Header = ({classicHeader, darkTheme, homeRef, handleNavClick, setDarkMode, navItems}) => {
    const {t, i18n} = useTranslation('app');
    const [isNavModalClose, setIsNavModalClose] = useState(true);

    return (
        <header id="header" className="sticky-top">
            {/* Navbar */}
            <nav className="primary-menu navbar navbar-expand-lg navbar-dark bg-dark border-bottom-0">
                <div className="container-fluid position-relative h-100 flex-lg-column ps-3 px-lg-3 pt-lg-3 pb-lg-2">
                    {/* Logo */}
                    <Link
                        smooth
                        duration={500}
                        style={{cursor: "pointer"}}
                        to="home"
                        className="mb-lg-auto mt-lg-4"
                        onClick={(e) => {
                            e.preventDefault();
                            setIsNavModalClose(true);
                        }}
                    >
                    <span className="bg-dark-2 rounded-pill p-2 mb-lg-1 d-none d-lg-inline-block">
                      <img
                          className="img-fluid rounded-pill d-block"
                          src={
                              "/images/"
                              + (darkTheme ? "L.jpg" : "M.jpg")
                          }
                          title="I'm René"
                          alt="profile"
                      />
                    </span>
                    <h1 className="text-5 text-white text-center mb-0 d-lg-block">
                        René Gerritsen
                    </h1>
                    </Link>
                    {/* Logo End */}
                    <div
                        id="header-nav"
                        className={
                            isNavModalClose
                                ? "collapse navbar-collapse w-100 my-lg-auto "
                                : "show navbar-collapse w-100 my-lg-auto"
                        }
                    >
                        <ul className="navbar-nav text-lg-center my-lg-auto py-lg-3">
                            {navItems.map((item, index) => (
                                (item.type === 'link') ?
                                    <li key={item.to} className="nav-item">
                                        <Link
                                            className="nav-link"
                                            key={item.to + '_link'}
                                            to={item.to}
                                            smooth={true}
                                            duration={500}
                                            style={{cursor: "pointer"}}
                                            activeclass="active"
                                            spy={true}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIsNavModalClose(true);
                                            }}
                                        >
                                            {item.name}
                                        </Link>
                                    </li>
                                    : <li key={index} className="nav-item">
                                        <RouterLink
                                            className="nav-link"
                                            to={item.to}
                                            style={{cursor: "pointer"}}
                                            activeclass="active"
                                        >
                                            {item.name}
                                        </RouterLink>
                                    </li>
                            ))}
                            <li className="d-block d-sm-none">
                                <Toggle
                                    defaultChecked={darkTheme}
                                    icons={false}
                                    className={"m-2"}
                                    onChange={setDarkMode}/>
                                <img
                                    className={"m-2"}
                                    alt="Seite in Deutscher Sprache betrachten"
                                    src="/de.jpg"
                                    style={{
                                        width: '25px',
                                        height: '14px',
                                        border: i18n.language === 'de' ? "1px solid white" : "none"
                                    }}
                                    onClick={() => i18n.changeLanguage('de')}
                                />
                                <img
                                    alt="view site in english language"
                                    className={"m-2"}
                                    src="/en.jpg"
                                    style={{
                                        width: '25px',
                                        height: '14px',
                                        border: i18n.language === 'en' ? "1px solid white" : "none"
                                    }}
                                    onClick={() => i18n.changeLanguage('en')}
                                />
                            </li>
                        </ul>
                    </div>
                    <ul className="social-icons social-icons-muted social-icons-sm mt-lg-auto ms-auto ms-lg-0 d-none d-sm-flex">
                        <li className="social-icons-github">
                            <Tooltip text="Github" placement="top">
                                <a
                                    href="https://github.com/re2bit"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-github"/>
                                </a>
                            </Tooltip>
                        </li>
                        <li className="social-icons-mastodon">
                            <Tooltip text="Mastodon" placement="top">
                                <a
                                    href="https://mastodon.social/@Xaer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-mastodon"/>
                                </a>
                            </Tooltip>
                        </li>
                        <li className="social-icons-email">
                            <a
                                data-toggle="tooltip"
                                href="mailto:rene.gerritsen@xaer.eu"
                                target="_blank"
                                rel="noopener noreferrer"
                                data-original-title="Email"
                            >
                                <i className="fas fa-envelope"/>
                            </a>
                        </li>
                    </ul>
                    <button
                        onClick={(e) => {
                            setIsNavModalClose(!isNavModalClose);
                        }}
                        className={
                            isNavModalClose ? "navbar-toggler" : "navbar-toggler show"
                        }
                        id="navbar-toggler"
                        type="button"
                    >
                        <span/>
                        <span/>
                        <span/>
                    </button>
                    <div className="d-none d-sm-block">
                        <Toggle
                            defaultChecked={darkTheme}
                            icons={false}
                            className={"m-2"}
                            onChange={setDarkMode}/>
                        <img
                            className={"m-2"}
                            alt="Seite in Deutscher Sprache betrachten"
                            src="/de.jpg"
                            style={{
                                width: '25px',
                                height: '14px',
                                border: i18n.language == 'de' ? "1px solid white" : "none"
                            }}
                            onClick={() => i18n.changeLanguage('de')}
                        />
                        <img
                            alt="view site in english language"
                            className={"m-2"}
                            src="/en.jpg"
                            style={{
                                width: '25px',
                                height: '14px',
                                border: i18n.language == 'en' ? "1px solid white" : "none"
                            }}
                            onClick={() => i18n.changeLanguage('en')}
                        />
                    </div>


                </div>
            </nav>
            {/* Navbar End */}

        </header>
    );
};

export default Header;
