import React from "react";
import Typewriter from "typewriter-effect";
import {useTranslation} from "react-i18next";

const Home = ({ classicHeader, darkTheme, handleNavClick }) => {
  const { t} = useTranslation('home');
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-2 bg-dark" />

        {/* ---------------image background------------------ */}
        <div
          className="hero-bg parallax"
          style={{ backgroundImage: (darkTheme ? 'url("images/ai_cityscape.jpeg")' : 'url("images/ai_cityscape_light.png")') }}
        ></div>

        {/* -------------------video background---------------------- */}

        {/* <div className="player hero-bg parallax">
          <video
            src={videobg}
            autoPlay
            muted
            loop
            style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          ></video>
        </div> */}

        <div className="hero-content section d-flex min-vh-100">
          <div className="container my-auto">
            <div className="row">
              <div className="col-12 text-center">
                <p className="text-7 fw-500 text-white mb-2 mb-md-3">Welcome</p>
                <h2 className="text-16 fw-600 text-white mb-2 mb-md-3">
                  <Typewriter
                    options={{
                      strings: [
                          t('introLine1'),
                          t('introLine2'),
                          t('introLine3'),
                          t('introLine4'),
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h2>
                <p className="text-5 text-light mb-4">
                    {t('basedInEurope')}
                </p>

              </div>
            </div>
          </div>
          <a
            href="#blog"
            className="scroll-down-arrow text-white smooth-scroll"
            onClick={(e) => {
              e.preventDefault();
              handleNavClick("blog");
            }}
          >
            <span className="animated">
              <i className="fa fa-chevron-down" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;

/*
                <a
                  href="#contact"
                  className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavClick("contact");
                  }}
                >
                    {t('hireMe')}
                </a>
 */
