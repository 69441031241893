import React from "react";
import {Link} from "react-router-dom";
import posts from "./Blog/posts.json"
const moment = require('moment');

const Blog = ({darkTheme, classicHeader, handleNavClick}) => {
    return (
        <section id="blog" className={"section " + (darkTheme ? "bg-dark-2" : "")}>
            <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
                {/* Heading */}
                <div className="position-relative d-flex text-center mb-5">
                    <h2
                        className={
                            "text-24  text-uppercase fw-600 w-100 mb-0 " +
                            (darkTheme ? "text-light opacity-1" : "text-light opacity-4")
                        }
                    >
                        Writings
                    </h2>
                    <p
                        className={
                            "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                            (darkTheme ? "text-white" : "text-dark")
                        }
                    >
                        Blog
                        <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"/>
                    </p>
                </div>
                {/* Heading end*/}

                <div className="my-4 row col-12">
                    {posts.filter(post => post.featured).map((post) => (
                        <div key={post.id} className="col-md-6">
                            <div
                                className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm position-relative">
                                <div className="col p-4 d-flex flex-column position-static">
                                    <strong className="text-primary">{post.category}</strong>
                                    <h2 className={"text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")}>
                                        {post.title}
                                    </h2>
                                    <strong
                                        className="text-secondary">{moment(post.date).format("DD.MM.YY")}</strong>
                                    <p className={"card-text mb-auto " + darkTheme ? "text-white-50" : ""}>{post.summary}</p>
                                    <Link to={`/blog/` + post.slug} className="icon-link gap-1 icon-link-hover stretched-link">Continue reading</Link>
                                </div>
                                <div className="col-auto d-none d-lg-block">
                                    <img alt={(post.summary.length > 20)?post.summary.substring(0,20) + '...' : post.summary}
                                         style={{width: '200px', height: '100%', objectFit: 'cover'}}
                                         src={"/images/blog/" + post.id + ".webp"}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="d-flex justify-content-center col-12">
                        <Link
                            className="col-auto center btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
                            to={`/blog#blog`}
                        >more...
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Blog;
